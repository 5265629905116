import React from 'react';
import StackedDateSelector from '../../../common/datepicker/StackedDateSelector';

export default {};

export const getUpdatedDateColumn = ({
  title = 'Date Updated',
  dataIndex = 'updatedDate',
  width = 250,
  requiredColumns = false,
  onChange,
  isDisplay = false,
}) => ({
  title: <div className={requiredColumns && 'form-required-field'}>{title}</div>,
  dataIndex,
  width,
  render: (updatedDates, record) => {
    const safeDatesArray = typeof updatedDates === 'string' ? updatedDates.split(', ') : [];

    return (
      <StackedDateSelector
        value={safeDatesArray}
        onChange={(val) => onChange(val, record)}
        isDisplay={isDisplay}
      />
    );
  },
});
