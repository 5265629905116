/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Tag, Row, Col } from 'antd';
import {
  FolderOutlined,
  GoldOutlined,
  EditOutlined,
} from '@ant-design/icons';

import Permissions from '../auth/Permissions';

import BorderlessButton from '../common/buttons/BorderlessButton';

import sortByString from '../helpers/helpers';

import {
  getPrice,
  getPriceFloat,
  changeIsNumber as changeChecker,
  hasMarkup,
  getMarkupString,
} from './materialsHelpers';

import MaterialsShareButton from './MaterialsShareButton';

export const priceRender = (_, record, discount = 0) => (
  hasMarkup(record.markup) && record.cost ? `$${(record.cost * (1 + record.markup) * (1 - discount)).toFixed(2)}` : ''
);

export default ({
  costcodeIdMap = {},
  equipmentMap = {},
  globalMaterialLocationsMap = {},
  onFolderEdit,
  selectedColumns,
  uniqueLocations = {},
  onShare,
}) => {
  const hasWritePerms = Permissions.hasWrite('MATERIALS');
  let cols = [];

  if (!selectedColumns || selectedColumns.has('code')) {
    cols.push({
      title: 'ID',
      dataIndex: 'code',
      width: 75,
      sorter: sortByString('code'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }
  cols = cols.concat([
    {
      title: <div style={{ paddingLeft: 20 }}>Name</div>,
      dataIndex: 'name',
      fixed: 'left',
      width: 150,
      sorter: sortByString('name'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => (
        <Row
          gutter={10}
          justify="start"
          style={{ paddingLeft: hasWritePerms ? 0 : 10 }}
        >
          <Col>
            {record.isFolder ? <FolderOutlined /> : <GoldOutlined />}
          </Col>
          <Col>
            {record.name}
          </Col>
          {
            (record.isFolder && hasWritePerms)
              ? (
                <Col>
                  <BorderlessButton
                    style={{ height: 22, margin: 0, padding: 0 }}
                    iconNode={<EditOutlined style={{ margin: '0px 5px' }} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      onFolderEdit(record);
                    }}
                  />
                </Col>
              ) : null
          }
        </Row>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      align: 'center',
      fixed: 'left',
      filters: [{ text: 'Active', value: '1' }, { text: 'Archived', value: '0' }],
      onFilter: (value, record) => parseInt(value, 10) === record.active,
      defaultFilteredValue: ['1'],
      render: (_, record) => {
        if (record.isFolder) return null;
        return (
          record.active
            ? <Tag color="darkgreen">Active</Tag>
            : <Tag color="default">Archived</Tag>
        );
      },
    },
  ]);

  if (!selectedColumns || selectedColumns.has('description')) {
    cols.push({
      title: 'Description',
      dataIndex: 'description',
      width: 150,
    });
  }

  if (!selectedColumns || selectedColumns.has('partNumber')) {
    cols.push({
      title: 'Part Number',
      dataIndex: 'partNumber',
      width: 150,
      sorter: sortByString('partNumber'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('supplier')) {
    cols.push({
      title: 'Supplier',
      dataIndex: 'supplier',
      width: 150,
      sorter: sortByString('supplier'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('cost')) {
    cols.push({
      title: 'Cost',
      dataIndex: 'cost',
      width: 150,
      render: (cost) => (cost ? `$${cost}` : ''),
      sorter: (a, b) => a.cost - b.cost,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('markup')) {
    cols.push({
      title: 'Markup',
      dataIndex: 'markup',
      width: 150,
      render: (markup, record) => (record.cost ? getMarkupString(markup) : ''),
      sorter: (a, b) => a.markup - b.markup,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('price')) {
    cols.push({
      title: 'Price',
      dataIndex: 'price',
      width: 150,
      render: (_, record) => priceRender(_, record),
      sorter: (a, b) => {
        const aPrice = hasMarkup(a.markup) && a.cost ? getPriceFloat(a.cost, a.markup) : a.cost;
        const bPrice = hasMarkup(b.markup) && b.cost ? getPriceFloat(b.cost, b.markup) : b.cost;
        return aPrice - bPrice;
      },
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('labourCost')) {
    cols.push({
      title: 'Labour Cost',
      dataIndex: 'labourCost',
      width: 150,
      render: (labourCost) => (labourCost ? `$${labourCost}` : ''),
      sorter: (a, b) => (
        (a.labourCost ?? Number.MIN_SAFE_INTEGER)
         - (b.labourCost ?? Number.MIN_SAFE_INTEGER)
      ),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('quantity')) {
    cols.push({
      title: 'Quantity',
      dataIndex: 'quantity',
      width: 150,
      sorter: (a, b) => a.quantity - b.quantity,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('quantityAllocated')) {
    cols.push({
      title: 'Quantity Allocated',
      dataIndex: 'quantityAllocated',
      width: 150,
      sorter: (a, b) => a.quantityAllocated - b.quantityAllocated,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('quantityAvailable')) {
    cols.push({
      title: 'Quantity Available',
      width: 150,
      sorter: ({
        quantity: quantityA = 0,
        quantityAllocated: quantityAllocA = 0,
      }, {
        quantity: quantityB = 0,
        quantityAllocated: quantityAllocB = 0,
      }) => (quantityA - quantityAllocA) - (quantityB - quantityAllocB),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (value, { quantity = 0, quantityAllocated = 0 }) => quantity - quantityAllocated,
    });
  }

  if (!selectedColumns || selectedColumns.has('quantityThreshold')) {
    cols.push({
      title: 'Quantity Threshold',
      dataIndex: 'quantityThreshold',
      width: 150,
      render: (quantityThreshold, record) => (record.isFolder ? '' : quantityThreshold),
      filters: [{ text: 'Above Threshold', value: 'above' }, { text: 'Under/Equal Threshold', value: 'under' }],
      onFilter: (value, record) => {
        if (record.isFolder) return false;
        if (value === 'above') {
          return record.quantity > record.quantityThreshold;
        }
        return record.quantity <= record.quantityThreshold;
      },
    });
  }

  if (!selectedColumns || selectedColumns.has('units')) {
    cols.push({
      title: 'Unit Type',
      dataIndex: 'units',
      width: 150,
      sorter: sortByString('units'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    });
  }

  if (!selectedColumns || selectedColumns.has('costcode')) {
    cols.push({
      title: 'Cost Code',
      dataIndex: 'costcode',
      width: 150,
      render: (_, record) => {
        const {
          [record.costcodeId]: {
            name: costcodeName,
          } = {},
        } = costcodeIdMap;
        return costcodeName;
      },
    });
  }

  if (!selectedColumns || selectedColumns.has('locations')) {
    cols.push({
      title: 'Locations',
      dataIndex: 'locations',
      align: 'center',
      width: 200,
      filters: Object.values(uniqueLocations),
      onFilter: (value, { locations = [] }) => (
        locations.some((record) => (
          value === record.globalMaterialLocationsId || value === record.equipmentId
        ))
      ),
      render: (locations = [], record = {}) => (
        record.isFolder
          ? null
          : locations.map(({ id: locationId, globalMaterialLocationsId, equipmentId }) => {
            const {
              [equipmentId]: {
                name,
              } = {},
            } = equipmentMap;
            const {
              [globalMaterialLocationsId]: {
                locationText: globalMaterialLocationsText,
              } = {},
            } = globalMaterialLocationsMap;
            return <Tag key={locationId}>{ name || globalMaterialLocationsText }</Tag>;
          })
      ),
    });
  }

  if (hasWritePerms) {
    cols.push({
      title: 'Permissions',
      key: 'permissions',
      width: 150,
      align: 'center',
      render: (_, record) => (
        record.isFolder
          ? <MaterialsShareButton onShare={onShare} record={record} />
          : null
      ),
    });
  }

  return cols;
};

export const getPreviewColumns = ({ type, change }) => {
  const changeIsNumber = changeChecker(change);
  const newClass = `material-bulk-change-preview-new-${change > 0 ? 'positive' : 'negative'}-value`;
  return (
    [
      {
        title: <div style={{ paddingLeft: 20 }}>Name</div>,
        dataIndex: 'name',
        fixed: 'left',
        width: 100,
        sorter: sortByString('name'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
        render: (val) => <div style={{ paddingLeft: 20 }}>{val}</div>,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        width: 100,
      },
      {
        title: 'Part Number',
        dataIndex: 'partNumber',
        width: 100,
        sorter: sortByString('partNumber'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Material Cost',
        dataIndex: 'cost',
        width: 100,
        render: (value, record) => {
          const { isFolder } = record;
          if (isFolder) return '';

          const cost = value || 0;

          if (type !== 'Cost' || !changeIsNumber || !change) return `$${cost}`;
          const newCost = (cost + change).toFixed(2);
          return (
            <>
              <span className="material-bulk-change-preview-old-value">{`$${cost}`}</span>
              <br />
              <span className={newClass}>{`$${newCost}`}</span>
            </>
          );
        },
        sorter: (a, b) => a.cost - b.cost,
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Labour Cost',
        dataIndex: 'labourCost',
        width: 100,
        render: (value, record) => {
          const { isFolder } = record;
          if (isFolder) return '';

          const cost = value || 0;

          if (type !== 'LabourCost' || !changeIsNumber || !change) return `$${cost}`;
          const newCost = (cost + change).toFixed(2);
          return (
            <>
              <span className="material-bulk-change-preview-old-value">{`$${cost}`}</span>
              <br />
              <span className={newClass}>{`$${newCost}`}</span>
            </>
          );
        },
        sorter: (a, b) => a.labourCost - b.labourCost,
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Markup',
        dataIndex: 'markup',
        width: 100,
        render: (markup, record) => {
          const { isFolder } = record;

          if (isFolder || !hasMarkup(markup)) return '';
          const markupPercent = markup * 100;
          if (type !== 'Markup' || !changeIsNumber || !change) return `${markupPercent}%`;
          const newMarkup = markupPercent + change;
          return (
            <>
              <span className="material-bulk-change-preview-old-value">{`${markupPercent}%`}</span>
              <br />
              <span className={newClass}>{`${newMarkup}%`}</span>
            </>
          );
        },
        sorter: (a, b) => a.markup - b.markup,
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Price',
        dataIndex: 'price',
        width: 100,
        render: (_, record) => {
          const { markup, cost: rawCost, isFolder } = record;
          if (isFolder) return '';
          const cost = rawCost || 0;
          if (!hasMarkup(markup)) return cost;
          const oldPrice = getPrice(cost, markup * 100);
          if (!changeIsNumber || !change || type === 'LabourCost') return oldPrice;
          const newCost = type === 'Cost' ? cost + change : cost;
          const newMarkup = type === 'Markup' ? (markup * 100) + change : markup * 100;
          const newPrice = getPrice(newCost, newMarkup);
          return (
            <>
              <span className="material-bulk-change-preview-old-value">{oldPrice}</span>
              <br />
              <span className={newClass}>{newPrice}</span>
            </>
          );
        },
        sorter: (a, b) => {
          const aPrice = hasMarkup(a.markup) && a.cost ? getPriceFloat(a.cost, a.markup) : a.cost;
          const bPrice = hasMarkup(b.markup) && b.cost ? getPriceFloat(b.cost, b.markup) : b.cost;
          return aPrice - bPrice;
        },
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
    ]
  );
};
