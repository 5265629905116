import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Divider, Drawer, Form } from 'antd';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import PaginatedTable from '../common/containers/PaginatedTable';

import {
  currencyFormatter,
  currencyParser,
  percentFormatter
} from '../helpers/inputParsers';

import { getPreviewColumns } from './materialTableColumns';

import { changeIsNumber } from './materialsHelpers';
import { convertLowerCamelCaseToText } from '../helpers/helpers';

export default ({
  type,
  visible,
  onClose,
  onSubmit,
  selected = [],
}) => {
  const isMaterialCost = type === 'Cost';
  const isLabourCost = type === 'LabourCost';
  const [change,setChange] = useState();

  const onSubmitClicked = useCallback(() => {
    onSubmit(change);
  },[change]);

  const numberInputProps = useMemo(() => (
    (isMaterialCost || isLabourCost) ? {
      formatter: currencyFormatter,
      parser: currencyParser,
      precision: 2,
      step: 0.01,
    } : {
      formatter: percentFormatter,
    }
  ),[isMaterialCost, isLabourCost]);

  const columns = useMemo(() => getPreviewColumns({ type, change }),[type, change]);
  const canSubmit = useMemo(() => changeIsNumber(change),[change]);

  useEffect(() => {
    if(!visible) setChange();
  },[visible]);

  const title =  type ? convertLowerCamelCaseToText(type) : type;

  return (
    <Drawer
      title={`Change ${title} for ${selected.length} items`}
      width={800}
      visible={visible}
      onClose={onClose}
    >
      <Form.Item label='Enter change'>
        <OnTraccrNumberInput
          style={{ width: '100%' }}
          {...numberInputProps}
          onChange={setChange}
          value={change}
        />
      </Form.Item>
      <Divider/>
      <PaginatedTable
        className='materials-table'
        scroll={{ x:'max-content' }}
        pagination={false}
        size='small'
        rowKey='id'
        columns={columns}
        dataSource={selected}
        scrollOffset={305}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
        canSubmit={canSubmit}
      />
    </Drawer>
  )
}
